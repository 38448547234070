<template>
  <a-select
    class="mb-3"
    :show-search="searchable"
    :allowClear="!noClear"
    :placeholder="placeholder"
    option-filter-prop="children"
    style="width: 100%;"
    :filter-option="filterOption"
    :disabled="disabled"
    :value="getKey(valueProxy)"
    :maxTagCount="tagMax"
    mode='multiple'
    :loading="loading"
    :notFoundContent="noOptionsText"
    @focus="handleFocus"
    @blur="handleBlur"
    @change="handleChange"
  >
    <a-select-option :value="getKey(option)" v-for="(option,i) in options" :key="i">
      {{getText(option)}}
    </a-select-option>
  </a-select>

</template>

<script>
import { KeyboardOnlyFocusMixin } from '../../../node_modules/vuestic-ui/src/components/vuestic-components/va-checkbox/KeyboardOnlyFocusMixin'

export default {
  name: 'va-select-c',
  data () {
    return {
      mode: 'default',
      open: false,
      ll: '',
    }
  },
  mixins: [KeyboardOnlyFocusMixin],
  props: {
    value: {},
    placeholder: String,
    options: {
      type: Array,
      default: () => [],
    },
    tagMax: {
      type: Number,
      default: 5,
    },
    searchable: Boolean,
    multiple: Boolean,
    disabled: Boolean,
    loading: Boolean,
    clearValue: {
      default: '',
    },
    width: {
      type: String,
      default: '100%',
    },
    keyBy: {
      type: String,
      default: 'id',
    },
    textBy: {
      type: String,
      default: 'text',
    },
    noOptionsText: {
      type: String,
      default: 'Items not found',
    },
    noClear: Boolean,
    error: Boolean,
    success: Boolean,
  },
  created () {
    console.log(this.value)
  },
  watch: {
    multiple: function (val) {
      if (val) {
        this.mode = 'multiple'
      }
    },
    value: function (val) {
      if (val === null || val === undefined) {
        this.value = ''
      }
    },
  },
  computed: {
    valueProxy: {
      get () {
        var val = this.value
        if (val === null || val === undefined) {
          val = ''
        }
        if (val[this.keyBy]) {
          return val
        } else {
          var selected = this.options.find(e => e[this.keyBy] === val)
          return selected
        }
      },
      set (value) {
        if (value) {
          var selected = this.options.find(e => e[this.keyBy] === value)
          this.$emit('input', selected)
        } else {
          this.$emit('input', this.clearValue)
        }
      },
    },
  },
  methods: {
    handleChange (value) {
      this.valueProxy = value
    },
    handleBlur () {
      console.log('blur')
    },
    handleFocus () {
      console.log('focus')
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    getText (option) {
      return typeof option === 'string' ? option : option[this.textBy]
    },
    getKey (option) {
      if (option) {
        return typeof option === 'string' ? option : option[this.keyBy]
      }
      return null
    },
  },
}
</script>

<style lang="scss">
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,string-no-newline */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: rgb(245, 248, 249);
  color: rgba(0, 0, 0, 0.65);
  min-height: 2.375rem;
  max-width: 285px;
  max-height: 98px;
  overflow-y: auto;
  border-style: solid;
  border-width: 0 0 thin 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  outline: 0;
}

.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-select > ul > li > a {
  padding: 0;
  background-color: #ffffff;
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  transform-origin: 50% 50%;
}

.ant-select-arrow > * {
  line-height: 1;
}

.ant-select-arrow svg {
  display: inline-block;
}

.ant-select-arrow::before {
  display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}

.ant-select-arrow .ant-select-arrow-icon svg {
  transition: transform 0.3s;
}

.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .ant-select-selection:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
} */

/* .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
} */
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  left: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #ffffff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}

.ant-select-selection__clear::before {
  display: block;
}

.ant-select-selection__clear:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}

.ant-select-selection-selected-value {
  float: right;
  max-width: 100%;
  overflow: hidden;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}

.ant-select-selection--single {
  position: relative;
  min-height: 2.375rem;
  height: 100%;
  cursor: pointer;
}

/* .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 24px;
} */
.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 7px;
}

.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 7px;
  margin-left: 11px;
  line-height: 30px;
}

.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}

.ant-select-lg {
  font-size: 16px;
}

.ant-select-lg .ant-select-selection--single {
  height: 40px;
}

.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}

.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}

.ant-select-sm .ant-select-selection--single {
  height: 24px;
}

.ant-select-sm .ant-select-selection__rendered {
  margin-left: 7px;
  line-height: 22px;
}

.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}

.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}

.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}

.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  left: 8px;
}

.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}

.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  left: 9px;
  right: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}

.ant-select-search__field__placeholder {
  right: 12px;
}

.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}

.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}

.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 4px;
  outline: 0;
}

.ant-select-search--inline > i {
  float: left;
}

.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}

.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}

.ant-select-selection--multiple::after {
  clear: both;
}

.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: right;
  width: auto;
  max-width: 100%;
  padding: 0;
}

.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
  padding: 1px;
}

.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}

.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: right;
  max-width: 98%;
  margin-left: 4px;
  padding: 0 10px 0 20px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}

.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}

:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}

.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}

.va-card__header-title {
  font-size: 1rem;
}

.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 20px;
}

.ant-select-open .ant-select-arrow-icon svg {
  transform: rotate(180deg);
}

.ant-select-open .ant-select-selection {
  outline: 0;
}

.ant-select-combobox .ant-select-arrow {
  display: none;
}

.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}

.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}

.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}

.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 7px;
}

.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #ffffff;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-menu {
  background-color: #e0dddd;
  max-height: 250px;
  margin-bottom: 0;
  padding: 4px 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}

.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}

.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 32px;
}

.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}

.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgba(239, 109, 16, 0.2);
}

.ant-select-dropdown-menu-item-selected {
  color: rgb(239, 109, 16);
  font-weight: 600;
  background-color: rgba(239, 109, 16, 0.2);
}

.ant-select-dropdown-menu-item-selected::after {
  content: '\2713';
  display: inline-block;
  color: rgb(239, 109, 16);
  font-weight: 600;
  padding: 0 6px 0 0;
}

.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: rgb(245, 248, 249);
}

.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  transform: translateY(-50%);
  transition: all 0.2s;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #1890ff;
}

.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-left: 12px;
}

.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}

</style>
