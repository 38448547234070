<template>

  <body>
    <div class='stars'></div>
    <div class="background">
      <div class="shape"></div>
      <div class="shape"></div>
    </div>
    <router-view/>
  </body>
</template>

<script>

const tabs = [
  'login',
  'signup',
]

export default {
  name: 'AuthLayout',
  data () {
    return {
      selectedTabIndex: 0,
      // tabTitles: ['login', 'createNewAccount'],
      tabTitles: ['login'],
    }
  },
  computed: {
    tabIndex: {
      set (tabIndex) {
        this.$router.push({ name: tabs[tabIndex] })
      },
      get () {
        return tabs.indexOf(this.$route.name)
      },
    },
  },
}
</script>

<style lang="css">

body {
  background-color: #080710;
}

.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}

.shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
}

.stars,
.clouds {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.stars {
  z-index: 0;
  background: #000000 url("https://i.imgur.com/YKY28eT.png") repeat top center;
}

</style>
