/* eslint-disable eqeqeq */
import http from '../../http-common'

class AuthService {
  login (data) {
    return http.post('Auth/login', data)
  }

  logout () {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
