import Vue from 'vue'

// add translations directly to the application
Vue.i18n.add('br', require('./pt_br.json'))
Vue.i18n.add('us', require('./en.json'))
Vue.i18n.add('es', require('./es.json'))
Vue.i18n.add('sa', require('./ar.json'))
Vue.i18n.add('cn', require('./cn.json'))
Vue.i18n.add('ir', require('./ir.json'))
var retrievedObject = localStorage.getItem('Lang')
if (retrievedObject) {
// set the start locale to use
  Vue.i18n.set(retrievedObject)
} else {
// set the start locale to use
  Vue.i18n.set('sa')
}

// set fallback for non-translated strings
Vue.i18n.fallback('sa')
