import axios from 'axios'
import authHeader from './http-authHeader'

export default axios.create({
  // baseURL: 'http://mahmmoudahmed-001-site7.ftempurl.com/api/v1/',
  // baseURL: 'https://yellow-sun.net/api/v1/',
  // baseURL: 'https://keep-dev.com/api/v1/',
  // baseURL: 'http://azhawebservice.keep-dev.com/api/v1/',
  baseURL: 'http://azhacars.keep-dev.com/api/v1/', // carsDemo
  // baseURL: 'https://cors-anywhere.herokuapp.com/mahmmoudahmed-001-site7.ftempurl.com/api/v1/',
  // baseURL: 'https://localhost:44347/api/v1/',
  headers: {
    'Content-type': 'application/json',
    Authorization: authHeader(),
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'Access-Controle-Allow-Origin': '*',
  },
})
