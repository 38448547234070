<template>
  <va-dropdown
    class="color-dropdown ltr"
    offset="0, 13px"
  >
    <va-icon-color
      slot="anchor"
      class="color-dropdown__icon"
      :color="contextConfig.invertedColor ? themeColors.navtext : 'white'"
    />
    <div class="color-dropdown__content pl-4 pr-4 pt-2 pb-2">
      <va-button-toggle
        outline
        small
        v-model="selectedTheme"
        :options="modeOptions"
        style="max-width: 100%;"
      />
      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="primary"
          slot="anchor"
        >
          Primary
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.primary"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="secondary"
          slot="anchor"
        >
          Secondary
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.secondary"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="success"
          slot="anchor"
        >
          Success
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.success"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="info"
          slot="anchor"
        >
          Info
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.info"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="danger"
          slot="anchor"
        >
          Danger
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.danger"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="warning"
          slot="anchor"
        >
          Warning
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.warning"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="gray"
          slot="anchor"
        >
          Gray
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.gray"
        />
      </va-dropdown>

      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="dark"
          slot="anchor"
        >
          Dark
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.dark"
        />
      </va-dropdown>
      <va-dropdown class="color-picker-dropdown mt-1 mb-1">
        <va-badge
          class="color-picker-dropdown__badge"
          color="navtext"
          slot="anchor"
        >
          Nav text
        </va-badge>
        <va-advanced-color-picker
          class="my-1"
          v-model="themeColors.navtext"
        />
      </va-dropdown>
    </div>
  </va-dropdown>
</template>

<script>
import VaIconColor from '../../../../../iconset/VaIconColor'

export default {
  inject: ['contextConfig'],
  components: {
    VaIconColor,
  },
  data () {
    return {
      themeColors: {},
    }
  },
  computed: {
    selectedTheme: {
      get () {
        return this.contextConfig.invertedColor ? 'corporate' : 'original'
      },
      set (themeName) {
        console.log('lll')
        this.$root.$emit('change-theme', themeName)
      },
    },
    modeOptions () {
      return [
        {
          label: 'Original',
          value: 'original',
        },
        {
          label: 'Corporate',
          value: 'corporate',
        },
      ]
    },
  },
  watch: {
    'themeColors.primary': function (val) {
      this.$themes = this.themeColors
      document.documentElement.style.setProperty('--scroll-color', val)
      document.documentElement.style.setProperty('--scrollHover-color', this.shadeColor(val, -40))

      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.secondary': function (val) {
      this.$themes = this.themeColors
      document.documentElement.style.setProperty('--scrollBg-color', val)
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.success': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.info': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.danger': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.warning': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.gray': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.dark': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
    'themeColors.navtext': function (val) {
      this.$themes = this.themeColors
      localStorage.setItem('themesColors', JSON.stringify(this.$themes))
    },
  },
  methods: {
    shadeColor (color, percent) {
      var R = parseInt(color.substring(1, 3), 16)
      var G = parseInt(color.substring(3, 5), 16)
      var B = parseInt(color.substring(5, 7), 16)

      R = parseInt(R * (100 + percent) / 100)
      G = parseInt(G * (100 + percent) / 100)
      B = parseInt(B * (100 + percent) / 100)

      R = (R < 255) ? R : 255
      G = (G < 255) ? G : 255
      B = (B < 255) ? B : 255

      var RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
      var GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
      var BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))

      return '#' + RR + GG + BB
    },
  },
  created () {
    this.themeColors = this.$themes
  },
}
</script>

<style lang="scss">

.color-dropdown {
  cursor: pointer;

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__content {
    background-color: $dropdown-background;
    box-shadow: $gray-box-shadow;
    border-radius: 0.5rem;

    .color-picker-dropdown {
      display: flex;
      justify-content: center;

      &__badge {
        /* Badges have 0.5rem to the right by default */
        margin-left: 0.5rem;
      }
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}

.button-restore {
  display: flex;
  margin: 0.375rem auto;
}
</style>
