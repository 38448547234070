<template>
  <va-button
    icon="fa fa-arrow-left"
    flat
    slot="actions"
    color="danger"
    class="mr-0"
    :title="$t('modal.back')"
    id="backBtn"
    @click="Reload()">
  </va-button>
</template>

<script>

export default {
  name: 'ReloadBtn',

  methods: {
    Reload () {
      this.$parent.$parent.$parent.showChildern = false
      this.$parent.$parent.$parent.stopBtn = false
      // location.reload()
    },
  },
}

</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
    }
  }

</style>
