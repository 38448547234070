import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import app from './modules/app'
import AuthService from '../services/AuthService/AuthService'
import * as getters from './getters'

Vue.use(Vuex)

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? {
    status: {
      loggedIn: true,
      mustChangePass: false,
    },
    user,
  }
  : {
    status: {
      loggedIn: false,
      mustChangePass: false,
    },
    user: null,
  }
const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
  },
  state: initialState,
  actions: {
    login ({ commit }, user) {
      commit('loginSuccess', user)
    },
    chngePass ({ commit }) {
      commit('chngePassSuccess')
    },
    logout ({ commit }) {
      AuthService.logout()
      commit('logout')
    },

  },
  mutations: {
    loginSuccess (state, user) {
      state.status.loggedIn = true
      state.status.mustChangePass = false
      state.user = user
    },
    chngePassSuccess (state) {
      state.status.mustChangePass = false
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.status.mustChangePass = false
      state.user = null
    },
    logout (state) {
      state.status.loggedIn = false
      state.status.mustChangePass = false
      state.user = null
    },
  },
})

Vue.use(VuexI18n.plugin, store)

export default store
